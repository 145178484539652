<template>
  <div class="edit">
    <el-form :model="form" :rules="formRules" ref="editForm" label-width="85px" @submit.prevent>
      <el-form-item label="名称" prop="name">
        <el-input v-model.trim="form.name" :size="controlSize" placeholder="名称" />
      </el-form-item>
      <el-form-item label="备注必填">
        <el-switch v-model="form.isRequire" :size="controlSize" />
      </el-form-item>
      <el-form-item label="在进度显示">
        <el-switch v-model="form.isShowInProcess" :size="controlSize" />
      </el-form-item>
      <el-form-item label="子流程">
        <ul class="child-list">
          <li class="child-item" v-for="(item, index) in form.child.filter(i => i.delflag)" :key="index">
            <i class="del el-icon-delete" title="删除" @click="delChild(index)"></i>
            <input v-model="item.name"
                   :ref="`childInput_${index}`"
                   placeholder="回车新增"
                   @keyup.enter="addChild('next')" />
          </li>
        </ul>
      </el-form-item>
    </el-form>
    <div class="btn-group">
      <el-button type="danger" :size="controlSize" @click="save">确定</el-button>
      <el-button type="info" :size="controlSize" @click="del" v-if="layerInfo.mode === 'edit'">删除</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layerInfo: Object
  },

  data() {
    return {
      controlSize: "mini",
      form: {
        name: "",
        isRequire: false,
        isShowInProcess: true,
        child: [],
        delflag: 1
      },

      formRules: {
        name: [{required: true, message: "请填写名称", trigger: "blur"}]
      }
    }
  },

  watch: {
    "form.child": {
      handler: function (arr) {
        !arr.filter(i => i.delflag).length && this.addChild()
      },
      deep: true
    }
  },

  mounted() {
    this.form = Object.assign(this.form, this.layerInfo)
    this.addChild()
  },

  methods: {
    async addChild(mode = "") {
      if (!this.form.child.filter(i => i.delflag).length || this.form.child[this.form.child.length - 1].name) {
        this.form.child.push({id: null, name: "", delflag: 1})
        await this.$nextTick()
        mode === "next" && this.$refs[`childInput_${this.form.child.length - 1}`].focus()
      }
    },

    save() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          if ([...new Set(this.form.child.filter(i => i.id || (!i.id && i.delflag)).map(i => i.name))].length !==
              this.form.child.filter(i => i.id || (!i.id && i.delflag)).map(i => i.name).length) {
            this.$message.warning("存在相同的子流程")
            return false
          }
          this.$emit('handle', {...this.form, child: this.form.child.filter(i => Boolean(i.id) || Boolean(!i.id && i.name && i.delflag))})
        }
      })
    },

    del() {
      this.form.delflag = 0
      this.$emit('handle', this.form)
    },

    delChild(index) {
      if (this.form.child[index].id) {
        this.form.child.splice(index, 1, {...this.form.child[index], delflag: 0})
      } else {
        this.form.child.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "edit";
</style>
